import React from 'react';

import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';

const MainMenu = () => {
    return (
        <>
            <nav className="menuMain">
                <ul>
                    <li className='nav-item'><Link to="/">Início</Link></li>
                    <li className='nav-item'>
                        <Link to='#'>Quem somos <MdKeyboardArrowDown /></Link>
                        <ul>
                            <li><Link to="/about">Sobre</Link></li>
                            <li><Link to="/expertises">Áreas de Atuação</Link></li>
                            <li><Link to="/history">Histórico</Link></li>
                            <li><Link to="/transparency">Transparência</Link></li>
                        </ul>
                    </li>
                    <li className='nav-item'><Link to="/solutions">Soluções</Link></li>
                    <li className='nav-item'>
                        <Link to="#">Projetos e Ações <MdKeyboardArrowDown /></Link>
                        <ul>
                            <li><Link to="/projects">Cadastro de Projetos</Link></li>
                            <li><Link to="/cases">Projetos Desenvolvidos</Link></li>
                        </ul>
                    </li>
                    <li className='nav-item'>
                        <Link to="#">Parceiros <MdKeyboardArrowDown /></Link>
                        <ul>
                            <li><Link className="menu-item" to="/associates">Associados Profissionais</Link></li>
                            <li><Link className="menu-item" to="/companies">Empresas</Link></li>
                            <li><Link className="menu-item" to="/ies">IES</Link></li>
                            <li><Link className="menu-item" to="/inst-sec">Organizações Representativas</Link></li>
                        </ul>
                    </li>
                    <li className='nav-item'><Link className="menu-item" to="/internship">Estágios</Link></li>
                    <li className='nav-item'><a href='https://www.itai.org.br/editora' target='_blank' rel='noreferrer' className="menu-item">Editora</a></li>
                    <li className='nav-item'><Link to="/evento">Eventos</Link></li>
                    <li className='nav-item'><Link to="/contact">Contato</Link></li>
                </ul>
            </nav>
        </>
    );
}

export default MainMenu;